import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

const IsCoolAVirtue = () => {
  return (
    <div className="container">
      <Helmet>
        <title> The Shoyaright! </title>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta name="twitter:title" content="Is Cool A Virtue?" />
        <meta
          name="twitter:image"
          content="http://shoya.co/static/shoyaright_icon_twitter_card_large_summary-ce9c728db92ba633cc195662666f7a8f.png"
        />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
      </Helmet>
      <link rel="stylesheet" href="../styles/global.css" />
      <div className="article">
        <Link to="/shoyaright">
          <div className="shoyarightChomskyArticle" />
        </Link>
        <div className="articleTitle">
          <p>Is Cool A Virtue?</p>
        </div>
        <div className="byLine">
          <p> By: Lil Shoyaright! (Pub. Mar. 23, 2022) </p>
        </div>
        <div className="articleBody">
          <p>
            Honestly, at this point in my life, I consider cool signaling a
            worse crime than virtue signaling.
          </p>
          <p>
            <span className="editorsNote">TRUE-COOL</span> &{" "}
            <span className="editorsNote">PSEUDO-COOL</span>
          </p>
          <p>
            The desire to gatekeep cultural artifacts comes from an insecurity
            about our own (in)ability to produce valuable cultural artifacts, in
            which case we tether our self-worth to our knowledge of cultural
            artifacts unknown to others. Therefore our sense of intrinsic value
            diminishes when others find out about said gatekept artifacts. (If I
            see another ‘y’all wasn’t outside’ tweet, I’m not sure what imma
            do).
          </p>
          <p>
            The mindset I just described is the primary symptom of what I’m
            calling <span className="editorsNote">PSEUDO-COOL</span>.
          </p>
          <p>
            <span className="editorsNote">TRUE-COOL</span> involves cultivating
            an attitude of abundance towards cultural knowledge, and knowing
            that ecclectic taste alone does not, in fact, make one superior to
            others. Rather, those who embody and exude{" "}
            <span className="editorsNote">TRUE-COOL</span> spend much of their
            time sharpening their ability to communicate their love of and
            enthusiasm for particular cultural phenomena, because their
            confidence in their own work does not preclude them from doing so
            widely (what’s more, they know that through the careful dissection
            and proliferation of a beautiful piece of culture, they are
            empowered further in their own practices).
          </p>
          <p>
            That’s why, despite how commonly hated and misunderstood they are,
            many professional critics are paragons of{" "}
            <span className="editorsNote">TRUE-COOL</span>.
          </p>
          <p>
            Don’t get me wrong, I’m guilty of having been a lightning rod for{" "}
            <span className="editorsNote">PSEUDO-COOL</span> in the past, and
            whether or not I’m <span className="editorsNote">TRUE-COOL</span>{" "}
            now really isn’t for me to say.
          </p>
          <p>
            What I <i>do</i> know is that molecular samples of{" "}
            <span className="editorsNote">PSEUDO-COOL</span> flourish in the
            petri dish of hyperconsumerism. Cuz in reality the difference
            between coveting clothing by an exclusive off-luxury designer brand
            and coveting <i>knowledge</i> of that brand is negligible,
            especially when you wield either of those things as a defensive
            weapon against introspection.
          </p>
          <p>
            To illustrate, here’s the stream of conscioussness of some sad soul
            operating on a <span className="editorsNote">PSEUDO-COOL</span>{" "}
            frequency:
          </p>
          <p>
            mine. mine. mine. miiiine. mine? OMG MINE. MINE? wait,
            MINE!!!??!?!?? :((( mine mine mine no mine stop no MINE please no
            please MINE? MINE? MINE?
            MIIIINEEEM!!!!MIN!#MIN!#!!!@MI#!@NMNI!@N:LKWJDGF:LKHSDJRFN:LAKG
          </p>
          <p>
            Toni Morrison (a conduit of{" "}
            <span className="editorsNote">TRUE-COOL</span> to say the least)
            said, “...just remember that your real job is that if you are free,
            you need to free somebody else. If you have some power, then your
            job is to empower somebody else. This is not just a grab-bag candy
            game.” She said this in a slightly different context, but the
            sentiment applies here — savviness about the beauty of the world is
            a kind of freedom and a kind of power that is never to be hoarded
            should you aspire to <span className="editorsNote">TRUE-COOL</span>.
          </p>
          <p>
            Just to be clear, though, I’m not saying that there are never
            cultural movements and byproducts of those movements that should be
            kept under wraps. <span className="editorsNote">IYKYK</span> energy
            is essential in many contexts. But{" "}
            <span className="editorsNote">IYKYK</span> is a powerful force that
            should only be deployed tactfully in the service of explicit
            artistic and political principles, and never at the whims of
            petulant egos.
          </p>
          <p>
            In short: those who embody{" "}
            <span className="editorsNote">TRUE-COOL</span> revere{" "}
            <span className="editorsNote">IYKYK</span> while those who channel{" "}
            <span className="editorsNote">PSEUDO-COOL</span> abuse it.
          </p>
          <p>
            And if you wanna see a few sparks of{" "}
            <span className="editorsNote">
              <span className="editorsNote">TRUE-COOL</span>
            </span>{" "}
            fly, just{" "}
            <a
              href="https://www.youtube.com/watch?v=CjfXm0eqOek"
              target="__blank"
              rel="noopener noreferrer"
            >
              have a look
            </a>{" "}
            .
          </p>
        </div>
        <p className="plug">
          If you enjoyed this article, consider{" "}
          <a href="https://www.patreon.com/join/shoyaright?">
            becoming a patron
          </a>{" "}
          or making a{" "}
          <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=zuff@shoya.co&lc=US&item_name=The%20Shoyaright%21&currency_code=USD&no_note=0&bn=PP-DonationsBF:btn_donateCC_LG.gif:NonHostedGuest">
            one-time pledge
          </a>
          . Support corporate journalism!
        </p>
        <div className="articleCopyright">
          <p>&copy; Shoya, Inc. 2020-</p>
        </div>
      </div>
    </div>
  )
}
export default IsCoolAVirtue
